import styled from "styled-components"
import img5 from "../../assets/img5.jpg"

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    width: 95%;
    max-width: 400px;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${img5});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    height: 200px;
  }
`

export const ContentContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;

  &:hover {
    color: #000;
  }
`

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const SubmitButton = styled.a`
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 12px;
  background-color: #2f4f4f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`

export const NoDiscountLink = styled.a`
  margin-top: 15px;
  font-size: 14px;
  color: #888;
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
    color: #555;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: 10px;
  }
`

export const Paragraf = styled.p`
  font-size: 16px;
  color: #444;
  margin-bottom: 15px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`
